import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'

import English from '../../../../img/english.svg'
import Hebrew from '../../../../img/hebrew.svg'
import Arrow from '../../../../img/arrow.svg'

class DesktopNavbar extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    toggleMenu = (param = '') => {
        if (!param) {
            this.setState(prevState => ({
                menuOpen: !prevState.menuOpen
            }))
        } else {
            this.setState(prevState => ({
                menuOpenSolutions: !prevState.menuOpenSolutions
            }))
        }

    }

    closeMenu = (param = '') => {
        if (!param) {
            this.setState({
                menuOpen: false
            })
        } else {
            this.setState({
                menuOpenSolutions: false
            })
        }

    }
    componentDidMount() {

    }

    render() {
        let navbarTXT = this.props.lang
        const { load_navbar } = this.props
        const load_navbar_style = {transition: 'all .5s' ,color: load_navbar ? '' : '#000'}
        let langType = navbarTXT.language
        const { arrow_load } = this.state
        return (
            <span className="navbar__links">
            <span className="navbar__links__text">
                <Link style={load_navbar_style} onClick={() => {  window.scrollTo(0,0) }} to={"/" + langType + "/solutions/parkvision"}><p className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>{navbarTXT.leftside1}</p></Link>
                <Link style={load_navbar_style} onClick={() => {  }} to={"/" + langType + "/services"}><p className={this.state.activeNav === 2 ? 'navbar__active-p' : 'navbar__p'}>{navbarTXT.leftside2}</p></Link>
                <Link style={load_navbar_style} onClick={() => {  }} to={"/" + langType + "/about"}><p className={this.state.activeNav === 3 ? 'navbar__active-p' : 'navbar__p'}>{navbarTXT.leftside3}</p></Link>
                <Link style={load_navbar_style} onClick={() => {  }} to={"/" + langType + "/contact"}><p className={this.state.activeNav === 4 ? 'navbar__active-p' : 'navbar__p'}>{navbarTXT.leftside4}</p></Link>
                <a  href="https://demo.metalparking.com/"><button style={{transition: 'all .5s' ,color: load_navbar ? '' : '#000', borderColor: load_navbar ? '' : '#000'}}>{navbarTXT.login}</button></a>  
            </span>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        this.closeMenu()
                    }}
                >
                <span className="navbar__links__lang" onClick={() => { this.toggleMenu() }}>
                    {langType=== 'en' ? <img src={English} alt="language" /> : <img src={Hebrew} alt="language" />}
                    <p style={load_navbar_style}>{navbarTXT.lang}</p>
                    <img onLoad={() => this.setState({ arrow_load: true })} src={Arrow} alt="Arrow" />
                    <aside 
                    className={this.state.menuOpen ? "navbar__links__lang__menu--active navbar__links__lang__menu" : "navbar__links__lang__menu"}
                    >
                        {
                            langType=== 'en' 
                            ?
                            ''
                            :
                            <span onClick={() => { this.props.changeLang('en') }}>
                                <img src={English} alt="language" />
                                <p>English</p>
                            </span>
                        }
                        {
                            langType=== 'he' 
                            ?
                            ''
                            :
                            <span onClick={() => { this.props.changeLang('he') }}>
                            <img src={Hebrew} alt="language" />
                            <p>עברית</p>
                        </span>
                        }
      
                    </aside>
                </span>
            </OutsideClickHandler>
        </span>
        )
    }
}
export default DesktopNavbar