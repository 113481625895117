import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import ScrollTop from './Components/ScrollTop'
import { Helmet } from 'react-helmet'
import axios from 'axios'
//components
import Home from './Components/Layouts/Home/Home'
import Solutions from './Components/Layouts/Solutions/Solutions'
import Services from './Components/Layouts/Services/Services'
import About from './Components/Layouts/About/About'
import Navbar from './Components/Globals/Navbar/Navbar'
import Footer from './Components/Globals/Footer/Footer'
import Contact from './Components/Layouts/Contact/Contact'
//langs
import * as english from './text/english'
import * as hebrew from './text/hebrew'
import { setCookie, getCookie } from './tools/cookie';
import { LANGUAGE_COOKIE } from './config/keys';


class App extends Component {
  constructor() {
    super()

    this.path = 0

    this.state = {
      lang: 'en'
    }
  }


  async componentWillMount() {
    const language_cookie = getCookie(LANGUAGE_COOKIE)
    if(language_cookie) {
      this.changeLang(language_cookie)
    } else {
      try {
        const res = await axios.get('https://ipdadassadapi.co/json/')
        const { country_name } = res.data
    
        if(window.location.pathname.indexOf('he') > -1 || country_name === 'Israel') {
          this.setState({lang: 'he'},()=>{
            this.changeLang('he')
          })
        } else {
          this.setState({lang: 'en'},()=>{
            this.changeLang('en')
          })
        }
      } catch(err) {
        if(window.location.pathname.indexOf('he') > -1) {
          this.setState({lang: 'he'},()=>{
            this.changeLang('he')
          })
        } else {
          this.setState({lang: 'en'},()=>{
            this.changeLang('en')
          })
        }
      }
      } 


  }


  componentWillReceiveProps(nextProps) {
    let path = nextProps.location.pathname
    if (path.indexOf('solutions') > -1) {
      this.path = 1
    } else if (path.indexOf('services') > -1) {
      this.path = 2
    } else if (path.indexOf('about') > -1) {
      this.path = 3
    } else if (path.indexOf('partners') > -1) {
      this.path = 4
    } else if (path.indexOf('clients') > -1) {
      this.path = 5
    } else {
      this.path = 0
    }
  }

  changeLang = (lang) => {
    this.setState({
      lang
    }, () => {
      setCookie(lang, LANGUAGE_COOKIE)
      if(window.location.pathname === '/') {
        this.props.history.push(`/${lang}`)
      }
      let url = window.location.pathname
      url = url.replace(/^.{3}/g, this.state.lang);
      url = '/' + url
      this.props.history.push(url)
    })
  }


  render() {
    let lang = this.state.lang === 'en' ? english : this.state.lang === 'he' ? hebrew : english
    let hebCheck = this.state.lang === 'he'
    return (
      <div className={hebCheck ? 'App hebrew' : 'App'}>
            <Helmet>
                <link rel="alternate" hreflang={this.state.lang} />
                <title>{hebCheck ? 'מטלפרקינג' : 'Metal Parking'}</title>
                <meta name="description" content={hebCheck ? `חברת מטלפרקינג בע“מ, מקבוצת מטלפרס, מתמחה בהתקנה, ייצור ופיתוח של מערכות חניה חכמות מאז שנת 2010. אנו מציעים מגוון רחב של פתרונות להכוונה וניהול החניון המאפשרים למנהל החניון לספק ללקוחותיו שירותים מתקדמים אשר הופכים את חווית החניה לנוחה וקלה.` : `MetalParking's smart parking technology creates new parking spaces and maximizes utilization of existing parking spaces using advanced sensors and a smart parking space management system`} />
            </Helmet>
        
        <ScrollTop>
          <div className="app-wrapper">
            <Navbar activeTab={this.path} lang={lang.navbarTXT} changeLang={this.changeLang} />
            <Switch>
              <Route path="/:lang/solutions" render={() => <Solutions lang={lang} />} />
              <Route path="/:lang/services" render={() => <Services lang={lang.servicesTXT} />} />
              <Route path="/:lang/about" render={() => <About lang={lang.aboutTXT} />} />
              <Route path="/:lang/contact" render={() => <Contact lang={lang.contactTXT} />} />
              <Route path="/:lang" render={() => <Home lang={lang.homePageTXT} />} />
            </Switch>
            <Footer lang={lang.FooterTXT} />
          </div>
        </ScrollTop>
      </div>

    );
  }
}

export default withRouter(App)
