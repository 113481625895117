export const navbarTXT = {
    leftside1: 'פתרונות',
    leftside2: 'שירותים',
    leftside3: 'אודות',
    leftside4: 'צור קשר',
    leftside5: 'לקוחות',
    leftside6: 'Park Vision',
    leftside7: 'Quick Park',
    leftside8: 'LED Guide Signs',
    leftside9: 'MetalOS',
    leftside10: 'Wireless Smart-Park',
    login: 'התחבר',

    dropDown1:'Park Vision',
    dropDown2:'Quick Park',
    dropDown3:'LED Guide signs',

    lang: 'עב',
    language: 'he'
}

export const homePageTXT = {
    firstSectionHeader: 'פתרונות החניה החכמה המתקדמים בעולם',
    firstSectionText1: 'הגיע הזמן להתקדם לפתרונות חניה חכמה ועתידנית',
    firstSectionText2: 'בשילוב הפיתוחים והפתרונות המתקדמים בעולם',
    firstSectionBtn: 'למד עוד',
    firstSectionBtn2: 'צור עימנו קשר',

    secondSectionHeader: 'הפתרונות שלנו',
    secondSectionText: 'ניסיון עשיר בהתקנות והפעלה של מערכות חניה מתקדמות הכוללות סנסורים, שילוט מוכוון ומערכות מידע חדשניות. אנו מובילים בפיתוח, ייצור ואספקה של סנסורים חכמים ומערכות ניהול מתקדמות, בין לקוחותינו המרוצים נמנים מאות עסקים בארץ ומכל רחבי העולם.',
    secondSectionFirstPicText: 'הכוונה קלה בזמן אמת',
    secondSectionFirstPicHeader: 'Led Guide Signs',
    secondSectionSecondPicText: 'סנסור מצלמה',
    secondSectionSecondPicHeader: 'Park Vision',
    secondSectionThirdPicText: 'סנסור אולטרסוני',
    secondSectionThirdPicHeader: 'Quick Park',
    secondSectionForthPicText:'הפקת נתונים ודיווחים בזמן אמת',
    secondSectionForthPicHeader:'MetalOS',
    secondSectionFifthPicHeader: 'Wireless Smart-Park',
    secondSectionFifthPicText: 'פתרון לחדשנות אורבנית',

    thirdSectionFirstHeader: 'הכי מנוסים',
    thirdSectionFirstText: 'מעל 10 שנות ותק ונסיון בתעשיית החניה החכמה, מעניקה לנו התמחות ייחודית בשוק הישראלי, בו אנו מובילים בפיתוח והתקנת סנסורים חכמים ומערכות ניהול מתקדמות.',
    thirdSectionSecondHeader: 'טכנולוגית קצה',
    thirdSectionSecondText: 'מטלפרקינג ייצרה והתקינה סנסורי חניה מתקדמת ומערכת ניהול בלמעלה מ50 חניונים, קניונים, בנייני משרדים, שדות תעופה בארץ ובאירופה.',
    thirdSectionThirdHeader: 'פתרון הוליסטי במקום אחד',
    thirdSectionThirdText: 'מהנדסי החומרה והתוכנה, השירות, מכירות וכלל אנשי השיווק. כולם עובדים יחדיו בשביל ההצלחה שלך.',

    forthSectionHeader: 'מותגים מובילים בחרו נכון כשבחרו במטלפרקינג',
    forthSectionText: 'למד עוד',

    fifthSectionHeading: 'טכנולוגיה מתקדמת',
    fifthSectionText: "כאן במטלפרקינג, משתמשים בטכנולוגיות מתקדמות, מבוססות דאטה, על מנת לספק פתרונות אולטימטיביים לחניה חכמה. בעזרת מערכות ניהול החניה החכמה שלנו, אנו מצליחים להעניק מידע חיוני לשליטה מלאה ואופטימיזציה לשימוש יעיל, וניצול מקסימלי של מקומות חניה בחניונים, בנייני משרדים ושדות תעופה. החיישנים ומערכות המידע החדשניות שפיתחנו, מאפשרים לנו להוביל במתן פתרונות יעילים וניהול אופטימלי של אזורי חניה.",
    fifthSectionBtn:'למד עוד',
    language: 'he'
}

export const FooterTXT = {
    footerFirstText: "חברת מטלפרקינג בע“מ, מקבוצת מטלפרס, מתמחה בהתקנה, ייצור ופיתוח של מערכות חניה חכמות מאז שנת 2010. אנו מציעים מגוון רחב של פתרונות להכוונה וניהול החניון המאפשרים למנהל החניון לספק ללקוחותיו שירותים מתקדמים אשר הופכים את חווית החניה לנוחה וקלה.",

    footerSecondHeading: 'פתרונות',
    footerSecondText1: 'Quick Park',
    footerSecondText2: 'Park Vision',
    footerSecondText3: 'Led Guide Signs',
    footerSecondText4: 'MetalOS',

    footerThirdHeading: 'שירותים',
    footerThirdText2: 'MetalOS',
    footerThirdText3: 'שירות ואחריות',
    footerThirdText4: 'אינטגרציות API',

    footerForthHeading: 'אודות',
    footerForthText1: 'הגישה שלנו',
    footerForthText2: 'למה מטלפרקינג',

    footerLastHeading: 'צור קשר',
    footerLastText1: 'מטלפרקינג בע״מ',
    footerLastText2: '03-5550346',
    footerLastText3: 'info@metalparking.com',
    footerLastText4: 'המלאכה 4, בת ים',

    language: 'he'
}

export const solutionTXT = {
    headerH1: 'פתרונות',
    headerText: 'ניסיון עשיר בהתקנות והפעלה של מערכות חניה מתקדמות הכוללות סנסורים, שילוט מוכוון ומערכות מידע חדשניות.',
    headerBtn1:'למד עוד',
    headerBtn2:'צור עימנו קשר',

    nav1:'Park Vision',
    nav2:'Quick Park',
    nav3:'LED Guide Signs',
    nav4:'MetalOS',
    nav5:'Wireless Smart-Park',

    allSectionBtn:'צור עימנו קשר',

    language: 'he'
}

export const solutionParkVisionTXT = {
    head1: 'שילוט הכוונה לחניה',
    text1:'הדור הבא של מערכות ההכוונה וניהול החניון המבוססת על טכנולוגיה של עיבוד תמונה.',
    head2: 'שירות Find My Car',
    text2:'זהה את מיקום רכבך בקלות עם שירות “Find My Car”',
    head3: 'פתרון חסכוני',
    text3:'טכנולוגית עיבוד תמונה עם יכולות ניטור של עד כ6 חניות בו זמנית',
    head4: 'חיווי תפוסה דו צדדי',
    text4:'סנסורים משולבי מצלמה המאפשרים לזהות מספר לוחית רישוי של הרכב החונה',
    btn1:'צור עימנו קשר',
    language: 'he'
}

export const solutionQuickParkTXT = {
    head1: 'דיוק מירבי',
    text1: 'המערכת המובילה בישראל לאיתור מהיר של חנייה פנויה בחניונים, המבוססת על גלאים אולטרה-סוניים, בקרים מתקדמים, שלטי הכוונה, תוכנת  ניהול ושרת ייעודיים. עם מעל ל-9 שנות ותק ונסיון בסביבות חניה שונות, Quick Park היא המערכת הכי ותיקה, מדויקת ומהימנה בתעשיית החניה החכמה',
    head2: 'הפחתת זמן חיפוש חניה',
    text2: 'המערכת מצוידת בנורות LED אשר מנטרים בזמן אמת את תפוסת החניה של כלל החניות, ומקלים על נהגים לזהות מרחוק היכן נמצאת חניה פנויה (אור ירוק) והיכן חניה תפוסה (אור אדום), ובכך, מפחיתים משמעותית את זמן חיפוש החניה.',
    btn1:'צור עימנו קשר',
    language: 'he'
}

export const solutionLedGuideTXT = {
    head1: 'הכוונה קלה בזמן אמת',
    text1: 'המערכת משתמשת בשלטי LED דינמיים המשתנים בזמן אמת, המראים מידע עדכני המגיע ישירות מהסנסורים הממוקמים לאורך החניון, ומעדכנים את הנהגים על זמינות ותפוסת החניה, כך שיוכלו להמנע מלהכנס לאזורי חניה תפוסים, ויודרכו להגיע לאזורי חניה פנוים במהירות ובקלות.',
    btn1:'צור עימנו קשר',
    language: 'en'
}

export const solutionMetalosTXT = {
    head1: 'MetalOS',
    text1: 'מבוססת על סנסורים אולטראסוניים, סנסורי מצלמה, אכיפה מתקדמת, שילוט הכוונה דיגיטלי, ומערכת הפעלה קלה ידידותית למשתמש. ל-MetalOS קיימת יכולת הפקת דוחות של איסוף ועיבוד שיטתי של נתונים מספריים בזמן אמת, וכך מספקת הבחנה ותובנה מעמיקה ביותר של הנתונים.',
    head2: 'תצוגה ויזואלית מתקדמת',
    text2: 'ויזואליות מלאה של סטטוס זמינות החניה בחניון',
    head3: 'השאר מעודכן',
    text3: 'מערך התראות למפעיל החניון והמשתמשים הכולל שליחת אימיילים, SMS והתראות שיחה',
    head4: 'הגדל רווחים',
    text4: 'מערכת MetalOS לומדת את התנהגות החניון לאורך זמן, ומציעה פתרונות לתמחור דינאמי, המבוסס על ניתוח שעות ואזורים שונים בחניון, מידע זה שימושי להגדלת המכירות.',
    btn1: 'למד עוד',
    btn2: ' צור עימנו קשר',
    language: 'he',
}

export const solutionWspTXT = {
    head1: 'מערכת ניטור חיצוני מתקדמת',
    text1: 'מערכת ייחודית של סנסורים אלחוטים בטכנולוגיית ניטור מתקדמת. המערכת מעניקה מידע חיוני לשליטה ואופטימיזציה לניצול מלא של מקומות חניה ופותחה במיוחד עבור חניונים פתוחים.',
    head2: 'מידע בזמן אמת',
    btn1:' צור עימנו קשר',
    language: 'he',
    text2:'המערכת מנטרת זמינות ותפוסת חניה ומעבירה את המידע בזמן אמת לשילוט מכוון ולמערכת ניהול החניון, על מנת לספק שירות אופטימלי לנהגים למפעילי החניון. הסנסורים מותקנים על פני הקרקע או בהטמנה בתוך האספלט, ומהווים תשתית חיונית בעולם הערים החכמות.'
}

export const servicesTXT = {
    firstSectionHeader: 'מערכות החניה החכמה שלנו',
    firstSectionText: 'מערכות החניה החכמה מבוססי הדאטה שלנו מעניקים מידע חיוני לשליטה מלאה ואופטימיזציה לניצול מירבי וניהול יעיל של מקומות חניה.',

    firstSectionBtn1:'למד עוד',
    firstSectionBtn2:'צור עימנו קשר',

    secondSectionHeader: 'MetalOS -מערכת ההפעלה לניטור ובקרה בזמן אמת',
    secondSectionText: 'השרת מעניק גישה ושליטה מלאה על הנתונים הבאים בזמן אמת',

    secondSectionIcon1: 'תצוגת ויזואלית מלאה של סטטוס זמינות החניה בחניון',
    secondSectionIcon2: 'תצוגת היסטורית של תפוסת החניון (מחולקת לפי אזורים או קומות)',
    secondSectionIcon3: 'דוחות של איסוף ועיבוד שיטתי של נתונים מספריים',
    secondSectionIcon4: 'חיבור לענן',
    secondSectionIcon5: 'הגדרות תצוגה שונות למשתמשים שונים',
    secondSectionIcon6: 'השוואה בין אזורי החניה',
    secondSectionIcon7: 'תמחור אזורי חניה דינאמי',
    secondSectionIcon8: 'מערך התראות למפעיל החניון והמשתמשים',

    thirdSectionLeftHeader: 'שירות התקנה ואחריות',
    thirdSectionLeftText: 'הטכנאים וצוותי הפיתוח שלנו מנטרים באופן קבוע את הסנסורים של מטלפרקינג. אנחנו גם מבקרים את הנכסים של הלקוחות שלנו באופן קבוע לתחזוקה שוטפת ומונעת תקלות, לוודא את אמינות ודיוק המערכות.',

    thirdSectionRightHeader: 'אינטגרציות API',
    thirdSectionRightText: "הנתונים והלוגיקה העסקית של מערכות המחשוב של שרתי מטלפרקינג יכולים להיות מאוכנסים ומגובים בענן, ונגישים לשיתוף עם מערכות שונות, גופים צד ג' ופיתוח אפליקציות דרך שילוב API מובנה.",

    thirdSectionRightFlex1: 'אכסון איסוף נתונים בענן של מטפלפרקינג',
    thirdSectionRightFlex2: 'חיבור למערכות חניה אחרות',
    thirdSectionLeftFlex3: 'פיתוח ממשק לתכנות מחשבים (API)',


    thirdSectionLeftFlex1: 'ניטור סנסורים',
    thirdSectionLeftFlex2: 'ביקור בבית הלקוח',
    thirdSectionRightFlex3: 'תיקון / החלפה מיידי',

    language: 'he'
}

export const aboutTXT = {
    topHeader: 'ברוכים הבאים לעידן חדש בענף החניה חכמה', 
    middleHeader:'הגישה שלנו',
    middleText1: 'תחום החניה החכמה הוא אחד התחומים החמים והמאתגרים של העולם המודרני עם הרבה יזמות, חדשנות עם פתרונות טכנולוגיים שעתידים לשנות באופן דרמטי את איכות החיים של כולנו. התקדם צעד אחד לפני כולם, לעבר חניה חכמה, כך שבעזרת שימוש נכון ויעיל במאגרי הנתונים, מובילה לחיסכון בהוצאות, מגבירה אפקטיביות בשעות העבודה, ומקדמת סביבה ירוקה. הטכנולוגיה המתקדמת של מטלפרקינג מביאה עימה שינויים מהותיים בתהליכים התעשייתים יוצרת מקומות חניה חדשים וממקסמת את השימוש בחניונים הקיימים, ע"י סנסורים חכמים ואמינים שפיתחנו.',
    middleText2: 'חברת מטלפרקינג בע“מ, מקבוצת מטלפרס, מתמחה בהתקנה, ייצור ופיתוח של מערכות חניה חכמות מאז שנת 2010. אנו מציעים מגוון רחב של פתרונות להכוונה וניהול החניון המאפשרים למנהל החניון לספק ללקוחותיו שירותים מתקדמים אשר הופכים את חווית החניה לנוחה וקלה.',
    bottomHeader: 'למה מטלפרקינג?',
    bottomText: "מטלפרקיננג עובדת בשיתוף עם מנהלי חניונים, בעלי קרקע ויזמים בתקני חניה, חברות בניה וקבלנים ועוד, בכדי לשפר את חווית החניה והשירות הניתן בנכס ומשדרגים את ערכו משמעותית כתוצאה מכך.",

    last1:'מעל 10 שנות ותק ונסיון',
    last2:'מעל 40,000 מקומות חניה מנוטרים בישראל ואירופה',
    last3:' צוות מנוסה של מפתחי תכנה, מהנדסים, אנשי שירות, שיווק ומכירות',
    language: 'he'
}

export const contactTXT = {
    header: 'צור איתנו קשר',
    subheader: 'צוות מטלפרקיננג ידאג לחזור אלייך בהקדם',

    last1:'מעל 10 שנות ותק ונסיון',
    last2:'מעל 40,000 מקומות חניה מנוטרים בישראל ואירופה',
    last3:' צוות מנוסה של מפתחי תכנה, מהנדסים, אנשי שירות, שיווק ומכירות',


    form1: 'שם חברה:',
    form2: 'אימייל:',
    form3: 'מספר טלפון:',
    form4: 'מדינה:',
    form5: 'הערות נוספות:',
    formBtn:'צור קשר',    footerLastText4: 'Hamelacha 4, Bat Yam',
    formBtnSub: 'פנייתך נשלחה',

    lang: 'he'
}