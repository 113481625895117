import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux' 
import reducers from './reducers/reducers.js'
import MetaTags from 'react-meta-tags';

const middleware = [thunk]
const store = createStore(
    reducers, 
    {}, 
    compose(applyMiddleware(...middleware))
)
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>

    </Provider>, 
    document.getElementById('root')
)


serviceWorker.unregister()
