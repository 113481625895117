import React, { Component } from 'react'
import * as EmailValidator from 'email-validator';
import axios from 'axios'
import ReactPhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import * as actions from '../../../actions/actions'

import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import BackgroundImageForLoad from '../../../img/contact/bg.png'
import dots1 from '../../../img/contact/dots1.png'
import dots2 from '../../../img/contact/dots2.png'
import dotsBig from '../../../img/contact/dotsbig.png'
import img1 from '../../../img/about/about_first.svg'
import img2 from '../../../img/about/about_second.svg'
import img3 from '../../../img/about/about_third.svg'
import Check from '../../../img/submited.svg'

import './contact.css'
import 'react-phone-input-2/dist/style.css'

class Contact extends Component {

    constructor() {
        super()

        this.state = {
            phone: '',
            country: '',
            company: '',
            email: '',
            notes: '',

            errphone: '',
            errcountry: '',
            errcompany: '',
            erremail: '',
            submitted: false,
            loader: false
        }
    }

    componentDidMount() {
        this.props.navbarLoadReset()
    }

    handleOnChange = (val) => {
        this.setState({ phone: val })
    }

    onSelectFlag = (val) => {
        this.setState({ country: val })
    }

    setCompany = (e) => {
        let value = e.target.value
        this.setState({ company: value })
    }

    setEmail = (e) => {
        let value = e.target.value
        this.setState({ email: value })
    }

    setNotes = (e) => {
        let value = e.target.value
        this.setState({ notes: value })
    }

    sendForm = () => {
        if (!this.state.submitted) {
            this.setState({ loader: true })
            const body = {
                company: this.state.company,
                email: this.state.email,
                phone: this.state.phone,
                country: this.state.country,
                notes: this.state.notes || ' ',
                created_at: Date.now()
            }

            let phoneError, emailError, companyError, countryError = false
            if (this.state.phone.length === 0) {
                phoneError = this.props.lang.lang === 'en' ? 'Please insert phone number':'אנא הכנס מספר טלפון'
                // phoneError = 'Please insert phone number'
            }
            if (this.state.phone.length < 6) {
                phoneError = 'Invalid phone number'
            }
            if (!this.state.company) {
                companyError = 'Please insert company name'
            }
            if (!this.state.country) {
                countryError = 'Please select country'
            }
            if (this.state.email.length === 0) {
                emailError = 'Please enter Email'
            }
            if (EmailValidator.validate(this.state.email) === false && this.state.email.length > 0) {
                emailError = 'Invalid Email address'
            }

            this.setState({
                errphone: phoneError,
                errcountry: countryError,
                errcompany: companyError,
                erremail: emailError,
            }, () => {
                if (!this.state.errphone && !this.state.errcompany && !this.state.erremail && !this.state.errcountry) {
                    axios.post('https://api.metalparking.com/contactus', body)
                        .then((response) => {
                            if (response.statusText === "OK") {
                                this.setState({
                                     submitted: true,
                                      loader: false,
                                      phone: '',
                                      country: '',
                                      company: '',
                                      email: '',
                                      notes: '',
                          
                                      errphone: '',
                                      errcountry: '',
                                      errcompany: '',
                                      erremail: '',
                                 })
                            }
                        })

                } else {
                    this.setState({
                        submitted: false,
                        loader: false,
                        phone: '',
                        country: '',
                        company: '',
                        email: '',
                        notes: '',
                    })
                }
            })
        }
    }

    render() {
        let contactTXT = this.props.lang
        const { background_loaded, img_one_loaded, img_two_loaded, img_three_loaded } = this.state
        return (
            <div className="contact">
                <div className="contact__first" />

                <div 
                    className="contact__grid"
                    style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                >
                    <img 
                        alt="cover"
                        className="home__cover__photo images__transitions contact__img__for__load" 
                        onLoad={() => {
                            this.props.navbarLoad()
                            this.setState({ background_loaded: true })
                        }} 
                        src={BackgroundImageForLoad} 
                        style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                    />
                    <div className="contact__title__container">
                        <h1 className="h1">{contactTXT.header}</h1>
                        <p className="p">{contactTXT.subheader}</p>
                    </div>
                    <img src={dots2} alt="dots2" />
                    <div className="contact__grid__last">
                        <div className="contact__form">
                        <aside className={this.state.loader || this.state.submitted ? "contact__form__loading contact__form__loading--active":"contact__form__loading"}>
                          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                           <img src={Check} alt="check" className={!this.state.loader && this.state.submitted ? "contact__form__loading__img contact__form__loading__img--active":"contact__form__loading__img"}/>
                        </aside>
                            <span>
                                <p>{contactTXT.form1}</p>
                                <section>
                                    <input type="text" placeholder="MetalParking" value={this.state.company} onChange={(e) => { this.setCompany(e) }} />
                                    <p>{this.state.errcompany}</p>
                                </section>
                            </span>



                            <span>
                                <p>{contactTXT.form2}</p>
                                <section>
                                    <input type="email" placeholder="metalparking@gmail.com" value={this.state.email} onChange={(e) => { this.setEmail(e) }} />
                                    <p>{this.state.erremail}</p>
                                </section>
                            </span>



                            <span>
                                <p>{contactTXT.form3}</p>
                                <section>
                                    <ReactPhoneInput defaultCountry={'il'} value={this.state.phone} value={this.state.phone} onChange={(e) => { this.handleOnChange(e) }} />
                                    <p>{this.state.errphone}</p>
                                </section>
                            </span>



                            <span>
                                <p>{contactTXT.form4}</p>
                                <section>
                                    <ReactFlagsSelect
                                        value={this.state.country}
                                        searchable={false}
                                        className="contact__form__countries"
                                        onSelect={(e) => { this.onSelectFlag(e) }} />
                                    <p>{this.state.errcountry}</p>
                                </section>

                            </span>



                            <span>
                                <p>{contactTXT.form5}</p>
                                <textarea onChange={(e) => { this.setNotes(e) }} />
                            </span>

                            <button style={this.state.submitted ? { opacity: '0.5', cursor: 'initial' } : {}} className="button-primary" onClick={() => { this.sendForm() }}>
                               <p>{contactTXT.formBtn}</p>
                            </button>

                            <img className="big__dots" src={dotsBig} alt="dotsbig" />
                            <img className="small__dots" src={dots1} alt="dots1" />
                        </div>
                        <div className="contact__info">
                            <div className="contact__info__box">
                                <p>{contactTXT.last1}</p>
                                <img 
                                    alt="calendar"
                                    onLoad={() => this.setState({ img_one_loaded: true })} 
                                    style={{opacity: img_one_loaded ? 1 : 0, transition: '.5s all'}}  
                                    src={img1} 
                                />
                            </div>
                            <div className="contact__info__box">
                                <p>{contactTXT.last2}</p>
                                <img 
                                    alt="planet earth"
                                    onLoad={() => this.setState({ img_two_loaded: true })} 
                                    style={{opacity: img_two_loaded ? 1 : 0, transition: '.5s all'}}  
                                    src={img2}
                                 />
                            </div>
                            <div className="contact__info__box">
                                <p>{contactTXT.last3}</p>
                                <img 
                                    alt="hands close together"
                                    onLoad={() => this.setState({ img_three_loaded: true })} 
                                    style={{opacity: img_three_loaded ? 1 : 0, transition: '.5s all'}}  
                                    src={img3} 
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps, actions)(Contact)