import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Close from '../../../../img/navbar/close.svg'
import Open from '../../../../img/navbar/menu.svg'
import Logo from '../../../../img/logo.svg'
import English from '../../../../img/english.svg'
import Hebrew from '../../../../img/hebrew.svg'
import Arrow from '../../../../img/arrow.svg'

class MobileNavbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            button: [1, 1, 1],
            canCloseNavbar: false
        }
    }
    toggleLanguageMenu () {
        this.setState({ langMenuOpen: !this.state.langMenuOpen })

    }

    toggleNabvar() {

    }

    componentDidMount() {

    }

    render() {
        const { button, langMenuOpen, mobileNavbar } = this.state
        const { load_navbar } = this.props
        const { language } = this.props.lang
        let navbarTXT = this.props.lang
        let langType = navbarTXT.language
        let transition = '.3s all'
        return (
            <span className="navbar__links">
            <a 
                className="mobile__login__button"
                href="https://demo.metalparking.com/"
            >
                <button
                    style={{
                        color: load_navbar ? '' : '#000',
                        borderColor: load_navbar ? '' : '#000'
                    }}>
                    {navbarTXT.login}
                </button>
            </a>  
            <div 
                onClick={() => this.setState({ mobileNavbar: true })}
                style={{zIndex: mobileNavbar ? 0 : 10000}}
                className="mobile__navbar__button"
            >
                <img src={Open} alt="mobile navbar open" />
            </div>
                <div style={{width: '100%', opacity: mobileNavbar ? 1 : 0, transition, pointerEvents: mobileNavbar ? '' : 'none'}} className="mobile__navbar mobile__navbar__items">
                <div style={{opacity: mobileNavbar ? 1 : 0}} onClick={() => this.setState({ mobileNavbar: false })} className="close__mobile__menu">
                    <img src={Close} alt="mobile navbar close" />
                </div>
                    <Link 
                        onClick={() => { this.changeActive(0) }} 
                        to={"/" + langType}> <img className="navbar__mobile__menu__logo" src={Logo} alt="metal-parking" /></Link>
                    <Link 
                        onClick={() => { this.changeActive(1); window.scrollTo(0,0) }} 
                        to={"/" + langType + "/solutions/parkvision"}>
                            <p 
                                className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside6.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(1); window.scrollTo(0,0) }} 
                        to={"/" + langType + "/solutions/wsp"}>
                            <p 
                                className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside10.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(1); window.scrollTo(0,0) }} 
                        to={"/" + langType + "/solutions/quickpark"}>
                            <p 
                                className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside7.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(1); window.scrollTo(0,0) }} 
                        to={"/" + langType + "/solutions/ledguide"}>
                            <p 
                                className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside8.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(1); window.scrollTo(0,0) }} 
                        to={"/" + langType + "/solutions/metalos"}>
                            <p 
                                className={this.state.activeNav === 1 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside9.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(2) }} 
                        to={"/" + langType + "/services"}>
                            <p 
                                className={this.state.activeNav === 2 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside2.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(3) }} 
                        to={"/" + langType + "/about"}>
                            <p 
                                className={this.state.activeNav === 3 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside3.toUpperCase()}
                            </p>
                    </Link>
                    <Link 
                        onClick={() => { this.changeActive(4) }} 
                        to={"/" + langType + "/contact"}>
                            <p 
                                className={this.state.activeNav === 4 ? 'navbar__active-p' : 'navbar__p'}>
                                {navbarTXT.leftside4.toUpperCase()}
                            </p>
                    </Link>
                    <span className="navbar__links__lang navbar__links__lang__mobile" onClick={() => { this.toggleLanguageMenu() }}>
                        {language === 'en' ? <img src={English} alt="language" /> : <img src={Hebrew} alt="language" />}
                        <p>{navbarTXT.lang}</p>
                        <img src={Arrow} alt="Arrow" />
                    </span>
                    <div style={{height: langMenuOpen ? '50px' : '0px', transition}} className="navbar__language__bar__mobile">
                        {
                            language === 'en'
                            ?
                            <span className="language__button__mobile" onClick={() => {this.setState({ mobileNavbar: false }); this.props.changeLang('he') }}>
                            <img src={Hebrew} alt="language" />
                            <p

                            >עברית</p>
                        </span>
                            :

                            <span className="language__button__mobile" onClick={() => {this.setState({ mobileNavbar: false }); this.props.changeLang('en') }}>
                            <img src={English} alt="language" />
                            <p>English</p>
                        </span>
                        }
  
                    </div>
                </div>
        </span>
        )
    }
}
export default MobileNavbar