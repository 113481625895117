import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import DesktopNavbar from './parts/DesktopNavbar'

import Logo from '../../../img/logo.svg'
import './navbar.css'
import MobileNavbar from './parts/MobileNavbar';

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeNav: this.props.activeTab || 0,
            menuOpen: false,
            menuOpenSolutions: false,
            navScrolled: false
        }
    }

    componentDidMount() {
        this.resize()

        window.addEventListener('scroll', this.activateNav);
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.activateNav);
        window.removeEventListener('resize', this.resize)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ activeNav: nextProps.activeTab })
    }

    resize = () => {
        if (window.innerWidth <= 1200) {
            this.setState({
                mobileNav: true
            })
        } else {
            this.setState({
                mobileNav: false
            })
        }
    }

    changeActive(value) {
        this.setState({ activeNav: value })
    }

    activateNav = () => {
        if(window.pageYOffset !== 0) {
            this.setState({navScrolled:true})
        } else {
            this.setState({navScrolled:false}) 
        }
    }

    render() {
        let navbarTXT = this.props.lang
        let langType = navbarTXT.language
        const { load_navbar } = this.props
        const { mobileNav } = this.state
        return (
            <div 
                className={this.state.navScrolled ? "navbar navbar--scrolled" : "navbar"}
            >
                <div 
                    className="navbar__flex"
                >
                    <span className="navbar__logo">
                        <Link onClick={() => { this.changeActive(0) }} to={"/" + langType}> 
                            <img src={Logo} alt="metal-parking" />
                        </Link>
                    </span>
                    {
                        mobileNav
                        ?
                            <MobileNavbar 
                                load_navbar={load_navbar}
                                changeLang={this.props.changeLang}
                                lang={navbarTXT} 
                                langType={langType} 
                            />
                        :
                            <DesktopNavbar 
                                load_navbar={load_navbar}
                                changeLang={this.props.changeLang}
                                lang={navbarTXT} 
                                langType={langType} 
                        />
                    }
                </div>
            </div>
        );
    }
}

function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps)(Navbar)