import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../actions/actions'
//img
import road from '../../../img/services/Illustration Abot.png'
import img1 from '../../../img/about/about_first.svg'
import img2 from '../../../img/about/about_second.svg'
import img3 from '../../../img/about/about_third.svg'
import { SOLUTIONS_TRANSITION } from '../../../config/keys';

import './about.css'

class About extends Component {
    componentDidMount() {
        this.props.navbarLoad()
    }
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let aboutTXT = this.props.lang
        const { img_loaded } = this.state
        return (
            <div className="about">
                <div className="about__nav__placeholder"></div>
                <div className="about__grid">
                    <div className="about__first-section">
                        <img 
                            onLoad={() => this.setState({ img_loaded: true })}
                            style={{opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION}} 
                            src={road} alt="" 
                        />
                        <h1
                            style={{opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION}} 
                        >
                            {aboutTXT.topHeader}
                        </h1>
                    </div>
                    <div className="about__middle">
                        <span className="about__middle__heading">
                            <h2 id="approach">{aboutTXT.middleHeader}</h2>
                        </span>

                        <span>
                            <p>{aboutTXT.middleText1}</p>
                            <p>{aboutTXT.middleText2}</p>
                        </span>
                    </div>
                    <div className="about__middle">
                        <span className="about__middle__heading">
                            <h2 id="whymetal">{aboutTXT.bottomHeader}</h2>
                        </span>

                        <div>
                            <p>{aboutTXT.bottomText}</p>
                        </div>
                    </div>
                    <div className="about__last">
                        <div className="about__info__box">
                            <p>{aboutTXT.last1}</p>
                            <img alt="calendar" src={img1} />
                        </div>
                        <div className="about__info__box">
                            <p>{aboutTXT.last2}</p>
                            <img alt="earth" src={img2} />
                        </div>
                        <div className="about__info__box">
                            <p>{aboutTXT.last3}</p>
                            <img alt="hands close together" src={img3} />
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}


function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps, actions)(About)