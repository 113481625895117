import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import '../solutions.css'
//img
import icon1 from '../../../../img/solutions/9.svg'
import icon2 from '../../../../img/solutions/10.svg'
import icon3 from '../../../../img/solutions/8.svg'
import icon4 from '../../../../img/solutions/11.svg'
import { SOLUTIONS_TRANSITION } from '../../../../config/keys';

class Metalos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let solutionMetalosTXT = this.props.lang
        const { img_loaded } = this.state
        let langType = solutionMetalosTXT.language
        return (
            <div className="park-vision">
                                <div className="tempo__header">
                    <h1>MetalOS</h1>
                </div>
                <div className="solution__img-section">
                <img 
                    onLoad={() => this.setState({ img_loaded: true })}
                    style={{opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION}} 
                    src={this.props.img} alt="" 
                    />
                </div>
                <div className="solution__text-flex">
                    <div>
                        <img src={icon1} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionMetalosTXT.head1}</h2>
                            <p>{solutionMetalosTXT.text1}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon2} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionMetalosTXT.head2}</h2>
                            <p>{solutionMetalosTXT.text2}</p>
                        </span>
                    </div>
                </div>

                <div className="solution__text-flex">
                    <div>
                        <img src={icon3} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionMetalosTXT.head3}</h2>
                            <p>{solutionMetalosTXT.text3}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon4} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionMetalosTXT.head4}</h2>
                            <p>{solutionMetalosTXT.text4}</p>
                        </span>
                    </div>
                </div>

                <div className="solutions-button">
                    <HashLink to={"/" + langType + "/services#metalos"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}> <button className="button-primary"><p>{solutionMetalosTXT.btn1}</p></button></HashLink>
                    <Link to={"/" + langType + "/contact"}> <button className="button-secondary"><p>{solutionMetalosTXT.btn2}</p></button></Link>
                </div>
            </div>
        );
    }
}

export default Metalos;