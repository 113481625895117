export const navbarTXT = {
    leftside1: 'Solutions',
    leftside2: 'Services',
    leftside3: 'About',
    leftside4: 'Contact',
    leftside5: 'Clients',
    leftside6: 'Park Vision',
    leftside7: 'Quick Park',
    leftside8: 'LED Guide Signs',
    leftside9: 'MetalOS',
    leftside10: 'Wireless Smart-Park',
    login: 'LOGIN',

    dropDown1:'Park Vision',
    dropDown2:'Quick Park',
    dropDown3:'LED Guide signs',

    lang: 'EN',
    language: 'en'
}

export const homePageTXT = {
    firstSectionHeader: 'Data - Driven Smart Parking Solutions',
    firstSectionText1: 'Step into the future of the world’s most advanced,',
    firstSectionText2: 'full-stack, smart parking solutions.',
    firstSectionBtn: 'LEARN MORE',
    firstSectionBtn2: 'CONTACT SALES',

    secondSectionHeader: 'Our Solutions',
    secondSectionText: 'Unmatched experience in installation and maintenance of parking guidance systems (PGS) and management systems, including advanced parking sensors and guiding lights. We are the leading suppliers of advanced parking guidance systems in Israel and the rest of the world. ',
    secondSectionFirstPicHeader: 'LED Guide Signs',
    secondSectionFirstPicText: 'Easy real-time navigation',
    secondSectionSecondPicHeader: 'Park Vision',
    secondSectionSecondPicText: 'Camera sensor',
    secondSectionThirdPicHeader: 'Quick Park',
    secondSectionThirdPicText: 'Ultrasonic Sensor',
    secondSectionForthPicHeader:'MetalOS',
    secondSectionForthPicText:'Real-time visual data',
    secondSectionFifthPicHeader: 'Wireless Smart-Park',
    secondSectionFifthPicText: 'Smart Urban Solution',

    thirdSectionFirstHeader: 'THE MOST EXPERIENCED',
    thirdSectionFirstText: '10 Years of experience in the smart-parking industry makes us the most experienced company in the Israeli market and a leader in the installation of sensors and smart-parking management systems.',
    thirdSectionSecondHeader: 'MOST ADVANCED TECHNOLOGY',
    thirdSectionSecondText: 'MetalParking has manufactured and deployed advanced parking sensors and smart management systems in more than 50 parking spaces and malls in Israel and all over Europe.',
    thirdSectionThirdHeader: 'FULL - STACK SOLUTIONS ',
    thirdSectionThirdText: 'Top of the line hardware and software engineers; Service, sales and marketing personnel. All working together to ensure your success.',


    forthSectionHeader: 'Leading brands make smart decisions choosing MetalParking',
    forthSectionText: 'LEARN MORE',

    fifthSectionHeading: 'Cutting-Edge Technology',
    fifthSectionText: 'At MetalParking, we use cutting-edge technology, powered by data, to provide the ultimate smart parking solutions. We’ve successfully boosted efficiency of parking operators, airports, corporations, and institutions’ lots, by management system optimization resulting in parking space upturn. Our smart parking spaces utilization is provided by our industry-leading tech solutions. ',
    fifthSectionBtn:'LEARN MORE',
    language: 'en'
}

export const FooterTXT = {
    footerFirstText: "MetalParking's smart parking technology creates new parking spaces and maximizes utilization of existing parking spaces using advanced sensors and a smart parking space management system.",

    footerSecondHeading: 'Solutions',
    footerSecondText1: 'Park Vision',
    footerSecondText2: 'Quick Park',
    footerSecondText3: 'LED Guide signs',
    footerSecondText4: 'MetalOS',

    footerThirdHeading: 'Services',
    footerThirdText1: 'SPT Services',
    footerThirdText2: 'MetalOS',
    footerThirdText3: 'Warranty & Service',
    footerThirdText4: 'API Integrations',

    footerForthHeading: 'About',
    footerForthText1: 'MetalParking Approach',
    footerForthText2: 'Why MetalParking',

    footerLastHeading: 'Quick Contact',
    footerLastText1: 'Metalparking LTD',
    footerLastText2: '+972-3-5550346',
    footerLastText3: 'info@metalparking.com',
    footerLastText4: 'Hamelacha 4, Bat Yam',

    language: 'en'
}

export const solutionTXT = {
    headerH1: 'Solutions',
    headerText: 'Unmatched experience in installation and maintenance of parking guidance systems (PGS) and management systems, including advanced parking sensors and guiding lights.',
    headerBtn1:'LEARN MORE',
    headerBtn2:'CONTACT SALES',

    nav1:'Park Vision',
    nav2:'Quick Park',
    nav3:'LED Guide Signs',
    nav4:'MetalOS',
    nav5:'Wireless Smart-Park',

    allSectionBtn:'CONTACT SALES',

    language: 'en'
}

export const solutionParkVisionTXT = {
    head1: 'Parking guidance system',
    text1:'The next generation of parking guidance system based on image recognition & processing technology.',
    head2: 'Find my car',
    text2:'Easily locate your car with “Find My Car” service.',
    head3: 'Cost effective',
    text3:'Single camera unit can monitor up to 6 spaces and availability of the spaces is shown with RGB led stripes.',
    head4: 'Live enforcement',
    text4:'Detection of license plates and parking spots across the parking space.',
    btn1:'CONTACT SALES',
    language: 'en'
}

export const solutionQuickParkTXT = {
    head1: 'Highest accuracy',
    text1: 'Start-Up Nation backed, most traditional and common parking guidance system (PGS) in Israel. With over 9 years of experience in various sites and environments, Quick-Park is the most reliable and accurate system in the smart parking industry.',
    head2: 'Minimize search time',
    text2: 'The system uses LED signs to indicate if a parking spot is occupied or not, making it easier for drivers to find a vacant spot from a distance and minimize search time spent on looking for a parking spot.',
    btn1:'CONTACT SALES',
    language: 'en'
}

export const solutionLedGuideTXT = {
    head1: 'Real-time data',
    text1: 'The system uses LED signs to portray real-time data from all sensors and inform drivers about the vacancy situation of all floors and regions, so they could avoid entering fully occupied areas and be directed to a vacant parking spot quickly and easily.',
    btn1:'CONTACT SALES',
    language: 'en'
}

export const solutionMetalosTXT = {
    head1: 'MetalOS',
    text1: 'Based on ultrasonic sensors, camera sensors, advanced controllers, digital guidance signs, and an easy-to-use operating system. MetalOS generates real-time stats and reports, providing unparalleled operational insights.',
    head2: 'Visual First',
    text2: 'Visualizing deep data-analysis of parking space status and a variety of reports and statistics.',
    head3: 'Stay notified',
    text3: 'Alert center for the parking operator, with Email, SMS and Call alerts.',
    head4: 'Increase revenue',
    text4: 'MetalOS learns your parking behavior during the day, suggests tailored pricing for different spots and hours.',
    btn1: 'LEARN MORE',
    btn2: 'CONTACT SALES',
    language: 'en',
}

export const solutionWspTXT = {
    head1: 'Wireless sensor',
    text1: 'A unique system of wireless outdoor sensors using most advanced monitoring technology. The system provides accurate parking data output for management and optimization purposes, resulting in utilization upturn of parking spaces and has been developed especially for outdoor parking lots.',
    head2: 'Advanced Outdoor Sensing',
    btn1:'CONTACT SALES',
    language: 'en',
    text2:'The system monitors the parking space occupancy and signals the data in real time to the digital guidance signs and parking management system, in order to provide optimal service to the drivers and the parking lot operators. The sensors are either installed on the ground or in-ground (hidden in the asphalt) and constitute an essential infrastructure to Smart City initiatives.'
}

export const servicesTXT = {
    firstSectionHeader: 'Our Smart Parking Technology Services',
    firstSectionText: 'Our data backed smart parking management systems provide valuable information for controlling and optimizing parking spaces resources.',

    firstSectionBtn1:'LEARN MORE',
    firstSectionBtn2:'CONTACT SALES',

    secondSectionHeader: 'Parking Data via MetalOS',
    secondSectionText: 'The centralized server provides access to the following real-time data',

    secondSectionIcon1: 'Visual display overview of parking space status',
    secondSectionIcon2: 'Historical data',
    secondSectionIcon3: 'Reports and statistics',
    secondSectionIcon4: 'Cloud connection',
    secondSectionIcon5: 'Member settings',
    secondSectionIcon6: 'Parking space compare',
    secondSectionIcon7: 'Dynamic parking pricing',
    secondSectionIcon8: 'Live notifications for both operator and members',

    thirdSectionLeftHeader: 'Warranty & Service',
    thirdSectionLeftText: 'Our technician & development team is constantly monitoring all of MetalParking’s sensors. We also visit our clients’ properties for ongoing preventative maintenance, to ensure the reliability and accuracy of the system.',

    thirdSectionRightHeader: 'API Integrations',
    thirdSectionRightText: 'All data from MetalParking systems can be automatically uploaded to the cloud and be accessed by other systems and third-party applications. Also available for developers through API.',

    thirdSectionRightFlex1: 'Store collected data in MetalParking Cloud',
    thirdSectionRightFlex2: 'Connect to other parking space systems',
    thirdSectionRightFlex3: 'Fix / replace on demand',

    thirdSectionLeftFlex1: 'Monitor sensors',
    thirdSectionLeftFlex2: 'Visit client site',
    thirdSectionLeftFlex3: 'Developers’ API.',
    language: 'en'
}

export const aboutTXT = {
    topHeader: 'Welcome to the new era of Smart Parking', 
    middleHeader:'The MetalParking Approach',
    middleText1: 'Parking shortage has become a significant scarce in modern times that has yet to be tackled by digital solutions.   With its advanced technology and vast experience, MetalParking leads innovation in the realm of smart parking and is a one-stop shop for all parking lot operational and management needs.',
    middleText2: 'MetalParking is a subsidiary of MetalPress Group, an operating manufacturer for over 70 years. MetalPress Group’s solutions include the optimization, project planning, production, and in-field installation of products based on the client’s unique needs.',
    bottomHeader: 'Why MetalParking?',
    bottomText: "MetalParking works together with parking managers, owners of parking assets, construction and infrastructure companies, operation companies and more to achieve the goal of improving the service given in an asset, resulting in asset's value upgrade.",

    last1:'We have 10 years of experience',
    last2:'Monitor over 40,000 parking spots in Israel & Europe',
    last3:'Passionate team of software developers, engineers, and service providers',
    language: 'en'
}


export const contactTXT = {
    header: 'Contact our team',
    subheader: 'Fill out the form and we’ll be in touch as soon as possible.',

    last1:'We have 10 years of experience',
    last2:'Monitor over 40,000 parking spots in Israel & Europe',
    last3:'Passionate team of software developers, engineers, and service providers',

    form1: 'Company name',
    form2: 'Email',
    form3: 'Phone number',
    form4: 'Country',
    form5: 'Additional Notes',

    formBtn:'CONTACT',
    formBtnSub: 'SENT',

    lang: 'en'
}