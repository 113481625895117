import { NAVBAR_LOAD, RESET_NAVBAR_LOAD } from '../actions/types'


const initialState = false

export default function(state = initialState, action) {
   
    switch (action.type) {
        case NAVBAR_LOAD:
            return true
        case RESET_NAVBAR_LOAD:
            return false
        default: {
            return state
        }
    }
}
