import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import './footer.css'
//img
import Logo from '../../../img/logo.svg'


class Footer extends Component {
    render() {
        let FooterTXT = this.props.lang
        let langType = FooterTXT.language
        return (
            <div className="footer">
                <div className="footer__flex">
                    <div className="footer__first-section">
                        <img src={Logo} alt="logo"/>
                        <p>{FooterTXT.footerFirstText}</p>
                    </div>
                    <div className="footer__second-section">
                        <span>
                            <h3>{FooterTXT.footerSecondHeading}</h3>
                           <Link to={"/" + langType + "/solutions/parkvision"} onClick={()=>{window.scrollTo(0,0)}}><p>{FooterTXT.footerSecondText1}</p></Link> 
                           <Link to={"/" + langType + "/solutions/quickpark"} onClick={()=>{window.scrollTo(0,0)}}><p>{FooterTXT.footerSecondText2}</p></Link> 
                           <Link to={"/" + langType + "/solutions/ledguide"} onClick={()=>{window.scrollTo(0,0)}}><p>{FooterTXT.footerSecondText3}</p></Link> 
                           <Link to={"/" + langType + "/solutions/metalos"} onClick={()=>{window.scrollTo(0,0)}}><p>{FooterTXT.footerSecondText4}</p></Link>
                        </span>
                        <span>
                            <h3>{FooterTXT.footerThirdHeading}</h3>
                            <Link to={"/" + langType + "/services#metalos"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}>
                                <p>{FooterTXT.footerThirdText2}</p>
                            </Link>
                            <Link to={"/" + langType + "/services#integrations"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}>
                                <p>{FooterTXT.footerThirdText3}</p>
                            </Link>
                            <Link to={"/" + langType + "/services#warrenty"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}>
                                <p>{FooterTXT.footerThirdText4}</p>
                            </Link>
                        </span>
                        <span>
                            <h3>{FooterTXT.footerForthHeading}</h3>
                            <Link to={"/" + langType + "/about#approach"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}>
                              <p>{FooterTXT.footerForthText1}</p>
                            </Link>
                            <Link to={"/" + langType + "/about#whymetal"} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -75) }}>
                              <p>{FooterTXT.footerForthText2}</p>
                            </Link>
                        </span>
                        <div className="footer__third-section">
                        <h3>{FooterTXT.footerLastHeading}</h3>
                        <p>{FooterTXT.footerLastText1}</p>
                        <p>{FooterTXT.footerLastText2}</p>
                        <p>{FooterTXT.footerLastText3}</p>
                        <p>{FooterTXT.footerLastText4}</p>
                    </div>
                    </div>

                    {FooterTXT.language === 'he' ?
                    <span className="footer__last_p">חברת מטלפרקינג בע”מ הינה חברה <a href="https://www.metalpress.co.il/" rel="noopener noreferrer" target="_blank">מקבוצת מטלפרס</a> </span>
                    :
                    <span className="footer__last_p">Metal Parking LTD. is part of of <a href="https://www.metalpress.co.il/en/" rel="noopener noreferrer" target="_blank">Metalpress group.</a></span>
                    }
                   
                </div>
            </div>
        );
    }
}

export default Footer;