import React, { Component } from 'react'
import './home.css'
import {Link} from 'react-router-dom'
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import { connect } from 'react-redux'
import * as actions from '../../../actions/actions'
//components
import Navbar from '../../Globals/Navbar/Navbar'
import NavbarPlaceHolder from '../../Globals/Navbar/NavbarPlaceholder'
import Footer from '../../Globals/Footer/Footer'
//imgs
import thirdSection1 from '../../../img/homethird1.svg'
import thirdSection2 from '../../../img/homethird2.svg'
import thirdSection3 from '../../../img/homethird3.svg'
import System from '../../../img/bg-with-mockup.png'
import fifth from '../../../img/homefifth.png'
import smallLogo from '../../../img/small-logo.svg'
import BackgroundImageForLoad from '../../../img/parkingBG.png'

import colab1 from '../../../img/1.png'
import colab2 from '../../../img/2.png'
import colab3 from '../../../img/3.png'
import colab4 from '../../../img/4.png'
import colab5 from '../../../img/5.png'
import colab6 from '../../../img/6.png'
import colab7 from '../../../img/7.png'
import colab8 from '../../../img/8.png'
import colab9 from '../../../img/9.png'
import colab10 from '../../../img/10.png'
import colab11 from '../../../img/11.png'
import colab12 from '../../../img/12.png'
import colab13 from '../../../img/13.png'
import colab14 from '../../../img/14.png'
import colab15 from '../../../img/15.png'


class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            background_loaded: false
        }
    }

    componentDidMount() {
        this.props.navbarLoadReset()
    }

    render() {
        let homePageTXT = this.props.lang
        const { background_loaded } = this.state
        let langType = homePageTXT.language
        const pics = [colab1, colab2, colab3, colab4, colab5, colab6, colab7, colab8, colab9, colab10, colab11, colab12, colab13, colab14, colab15]
        configureAnchors({offset: -85, scrollDuration: 400})
        return (
            <div className="home">
                <div className="home__grid">
                    <div 
                    style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}} 
                    className="home__first-section">
                        <img 
                            alt="parking lot"
                            className="home__cover__photo images__transitions" 
                            onLoad={() => {
                                this.props.navbarLoad()
                                this.setState({ background_loaded: true })
                            }} 
                            src={BackgroundImageForLoad} 
                            style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                        />
                        <h1>{homePageTXT.firstSectionHeader}</h1>
                        <h3>{homePageTXT.firstSectionText1}</h3>
                        <h3>{homePageTXT.firstSectionText2}</h3>
                        <span className="button__container">
                        <a href='#section1'> 
                        <button className="button-primary"><p>{homePageTXT.firstSectionBtn}</p></button></a>
                            <Link to={"/" + langType + "/contact"}><button className="button-secondary"><p>{homePageTXT.firstSectionBtn2}</p></button></Link>
                            
                        </span>
                    </div>
                    <ScrollableAnchor id={'section1'}>
                    <div className="home__second-section">
                        <div className="home__second-section__text">
                            <h1>{homePageTXT.secondSectionHeader}</h1>
                            <p>{homePageTXT.secondSectionText}</p>
                            <Link to={"/" + langType + "/solutions/parkvision"}> <button className="button-primary"><span>{homePageTXT.fifthSectionBtn}</span></button></Link>
                        </div>
                        <div className="home__second-section__examples">
                            <figure>
                                <Link to={"/" + langType + "/solutions/quickpark"}>
                                    <div />
                                    <h3>{homePageTXT.secondSectionThirdPicHeader}</h3>
                                    <p>{homePageTXT.secondSectionThirdPicText}</p>
                                    <img src={smallLogo} alt="logo"/>
                                </Link>
                            </figure>
                            <figure>
                                <Link to={"/" + langType + "/solutions/parkvision"}>
                                    <div />
                                    <h3>{homePageTXT.secondSectionSecondPicHeader}</h3>
                                    <p>{homePageTXT.secondSectionSecondPicText}</p>
                                    <img src={smallLogo} alt="logo"/>
                                </Link>
                            </figure>
                            <figure>
                                <Link to={"/" + langType + "/solutions/wsp"}>
                                    <div />
                                    <h3>{homePageTXT.secondSectionFifthPicHeader}</h3>
                                    <p>{homePageTXT.secondSectionFifthPicText}</p>
                                    <img src={smallLogo} alt="logo"/>
                                </Link>
                            </figure>
                            <figure>
                                <Link to={"/" + langType + "/solutions/ledguide"}>
                                    <div />
                                    <h3>{homePageTXT.secondSectionFirstPicHeader}</h3>
                                    <p>{homePageTXT.secondSectionFirstPicText}</p>
                                    <img src={smallLogo} alt="logo"/>
                                </Link>
                            </figure>

                            <figure>
                                <Link to={"/" + langType + "/solutions/metalos"}>
                                    <div className="last__link__home" />
                                    <h3>{homePageTXT.secondSectionForthPicHeader}</h3>
                                    <p>{homePageTXT.secondSectionForthPicText}</p>
                                    <img src={smallLogo} alt="logo"/>
                                </Link>
                            </figure>
                        </div>
                    </div>
                    </ScrollableAnchor>

                    <div className="home__third-section">
                        <div className="home__third-section__text">
                            <div>
                                <img src={thirdSection1} alt="experience" />
                                <span>
                                    <h4>{homePageTXT.thirdSectionThirdHeader}</h4>
                                    <p>{homePageTXT.thirdSectionThirdText}</p>
                                </span>
                            </div>
                            <div>
                                <img src={thirdSection2} alt="tech" />
                                <span>
                                    <h4>{homePageTXT.thirdSectionSecondHeader}</h4>
                                    <p>{homePageTXT.thirdSectionSecondText}</p>
                                </span>
                            </div>
                            <div>
                                <img src={thirdSection3} alt="solutions" />
                                <span>
                                    <h4>{homePageTXT.thirdSectionFirstHeader}</h4>
                                    <p>{homePageTXT.thirdSectionFirstText}</p>
                                </span>
                            </div>
                        </div>
                    </div>


                    <div className="home__forth-section">

                        <span className="home__forth-section__content__text">
                            <h2>{homePageTXT.forthSectionHeader}</h2>
                        </span>

                        <span className="home__forth-section__content__icons">
                            <div>
                                {pics.map(item => {
                                    return <span>
                                        <img src={item} alt="colaborator" />
                                    </span>
                                })}
                            </div>
                        </span>

                    </div>

                    <div className="home__fifth-section">
                        <span>
                            <h1>{homePageTXT.fifthSectionHeading}</h1>
                            <p>{homePageTXT.fifthSectionText}</p>
                            <Link to={"/" + langType + "/services"}><button className="button-primary"><p>{homePageTXT.fifthSectionBtn}</p></button></Link>
                        </span>

                        <img src={fifth} alt="system" />
                    </div>

                </div>

            </div>
        );
    }
}

function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps, actions)(Home)