import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../solutions.css'
//img
import icon1 from '../../../../img/solutions/5.svg'
import icon2 from '../../../../img/solutions/6.svg'
import { SOLUTIONS_TRANSITION } from '../../../../config/keys';

class QuickPark extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let solutionQuickParkTXT = this.props.lang
        const { img_loaded } = this.state
        let langType = solutionQuickParkTXT.language

        return (
            <div className="quick-park">
                <div className="tempo__header">
                    <h1>Quick Park</h1>
                </div>
                <div className="solution__img-section">
                    <img
                        onLoad={() => this.setState({ img_loaded: true })}
                        style={{ opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION }}
                        src={this.props.img} alt=""
                    />
                </div>
                <div className="solution__text-flex">
                    <div>
                        <img src={icon1} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionQuickParkTXT.head1}</h2>
                            <p>{solutionQuickParkTXT.text1}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon2} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionQuickParkTXT.head2}</h2>
                            <p>{solutionQuickParkTXT.text2}</p>
                        </span>
                    </div>
                </div>

                <div className="solutions-button">
                    <Link to={"/" + langType + "/contact"}> <button className="button-primary"><p>{solutionQuickParkTXT.btn1}</p></button></Link>
                </div>
            </div>
        );
    }
}

export default QuickPark;