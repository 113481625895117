import React, { Component } from 'react'
import './services.css'
import { Link } from 'react-router-dom'
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import { connect } from 'react-redux'
import * as actions from '../../../actions/actions'
//img
import System from '../../../img/services/computer.png'
import BackgroundImageForLoad from '../../../img/services/header-bg.png'
import icon10 from '../../../img/services/1.svg'
import icon11 from '../../../img/services/2.svg'
import icon12 from '../../../img/services/3.svg'

import icon13 from '../../../img/services/12.svg'
import icon14 from '../../../img/services/13.svg'
import icon15 from '../../../img/services/14.svg'

import icon1 from '../../../img/services/4.svg'
import icon2 from '../../../img/services/5.svg'
import icon3 from '../../../img/services/6.svg'
import icon4 from '../../../img/services/7.svg'
import icon5 from '../../../img/services/8.svg'
import icon6 from '../../../img/services/9.svg'
import icon7 from '../../../img/services/10.svg'
import icon8 from '../../../img/services/11.svg'


class Services extends Component {
    componentDidMount() {
        this.props.navbarLoadReset()
    }
    constructor(props) {
        super(props)
        this.state = {
            background_loaded: false
        }
    }
    render() {
        let servicesTXT = this.props.lang
        const { background_loaded, system_loaded } = this.state
        let langType = servicesTXT.language
        configureAnchors({ offset: -85, scrollDuration: 400 })
        return (
            <div className="services">
                <div 
                    className="services__first-section"
                    style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                >
                    <img 
                        alt="parked cars in parking lot"
                        className="home__cover__photo images__transitions" 
                        onLoad={() => {
                            this.props.navbarLoad()
                            this.setState({ background_loaded: true })
                        }} 
                        src={BackgroundImageForLoad} 
                        style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                    />
                    <h1>{servicesTXT.firstSectionHeader}</h1>
                    <h3>{servicesTXT.firstSectionText}</h3>
                    <span className="button__container">
                        <a href='#navsolutions'><button className="button-primary"><p>{servicesTXT.firstSectionBtn1}</p></button></a>
                        <Link to={"/" + langType + "/contact"}><button className="button-secondary"><p>{servicesTXT.firstSectionBtn2}</p></button></Link>
                    </span>
                </div>
                <ScrollableAnchor id={'metal'}>
                    <div className="services__second-section">
                        <h1 id="metalos">{servicesTXT.secondSectionHeader}</h1>
                        <p>{servicesTXT.secondSectionText}</p>
                        <img 
                            style={{opacity: system_loaded ? 1 : 0, transition: '.5s all'}}  
                            onLoad={() => this.setState({ system_loaded: true })} 
                            src={System} alt="System" 
                        />
                        <div className="services__second-section__icons">
                            <span>
                                <aside>
                                    <img src={icon1} alt="" />
                                    <p>{servicesTXT.secondSectionIcon1}</p>
                                </aside>
                                <aside>
                                    <img src={icon2} alt="" />
                                    <p>{servicesTXT.secondSectionIcon2}</p>
                                </aside>
                                <aside>
                                    <img src={icon3} alt="" />
                                    <p>{servicesTXT.secondSectionIcon3}</p>
                                </aside>
                                <aside>
                                    <img src={icon4} alt="" />
                                    <p>{servicesTXT.secondSectionIcon4}</p>
                                </aside>
                            </span>
                            <span>
                                <aside>
                                    <img src={icon5} alt="" />
                                    <p>{servicesTXT.secondSectionIcon5}</p>
                                </aside>
                                <aside>
                                    <img src={icon6} alt="" />
                                    <p>{servicesTXT.secondSectionIcon6}</p>
                                </aside>
                                <aside>
                                    <img src={icon7} alt="" />
                                    <p>{servicesTXT.secondSectionIcon7}</p>
                                </aside>
                                <aside>
                                    <img src={icon8} alt="" />
                                    <p>{servicesTXT.secondSectionIcon8}</p>
                                </aside>
                            </span>
                        </div>
                    </div>
                </ScrollableAnchor>

                <div className="services__last-section">
                    <div className="services__last-section__content">
                        <h1 id="warrenty">{servicesTXT.thirdSectionLeftHeader}</h1>
                        <p>{servicesTXT.thirdSectionLeftText}</p>

                        <div className="services__last-section__icons">
                            <span>
                                <img src={icon13} alt="functions" />
                                <p>{servicesTXT.thirdSectionLeftFlex1}</p>
                            </span>
                            <span>
                                <img src={icon14} alt="functions" />
                                <p>{servicesTXT.thirdSectionLeftFlex2}</p>
                            </span>
                            <span>
                                <img src={icon15} alt="functions" />
                                <p>{servicesTXT.thirdSectionRightFlex3}</p>
                            </span>
                        </div>

                    </div>
                    <div className="services__last-section__content">
                        <h1 id="integrations">{servicesTXT.thirdSectionRightHeader}</h1>
                        <p>{servicesTXT.thirdSectionRightText}</p>

                        <div className="services__last-section__icons services__last__section__div">
                            <span className="services__last__span">
                                <img src={icon10} alt="functions" />
                                <p>{servicesTXT.thirdSectionRightFlex1}</p>
                            </span>
                            <span className="services__last__span">
                                <img src={icon11} alt="functions" />
                                <p>{servicesTXT.thirdSectionRightFlex2}</p>
                            </span>
                            <span className="services__last__span last__services__span">
                                <img src={icon12} alt="functions" />
                                <p>{servicesTXT.thirdSectionLeftFlex3}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps, actions)(Services)