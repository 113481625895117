import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../solutions.css'
//img
import icon1 from '../../../../img/solutions/7.svg'
import { SOLUTIONS_TRANSITION } from '../../../../config/keys';

class LedGuide extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let solutionLedGuideTXT = this.props.lang
        const { img_loaded } = this.state
        let langType = solutionLedGuideTXT.language
        return (
            <div className="led-guide">
                                <div className="tempo__header">
                    <h1>Led Guide Signs</h1>
                </div>
                <div className="solution__img-section">
                <img 
                    onLoad={() => this.setState({ img_loaded: true })}
                    style={{opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION}} 
                    src={this.props.img} alt="" 
                    />
                </div>
                <div className="solution__text-flex">
                    <div>
                        <img src={icon1} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionLedGuideTXT.head1}</h2>
                            <p>{solutionLedGuideTXT.text1}</p>
                        </span>
                    </div>
                </div>

                <div className="solutions-button">
                    <Link to={"/" + langType + "/contact"}> <button className="button-primary"><p>{solutionLedGuideTXT.btn1}</p></button></Link>
                </div>
            </div>
        );
    }
}

export default LedGuide;