import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../solutions.css'
//img
import icon1 from '../../../../img/solutions/1.svg'
import icon2 from '../../../../img/solutions/3.svg'
import icon3 from '../../../../img/solutions/2.svg'
import icon4 from '../../../../img/solutions/4.svg'
import { SOLUTIONS_TRANSITION } from '../../../../config/keys';

class ParkVision extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let solutionParkVisionTXT = this.props.lang
        const { img_loaded } = this.state
        let langType = solutionParkVisionTXT.language
        return (
            <div className="park-vision">
                <div className="tempo__header">
                    <h1>Park Vision</h1>
                </div>
                <div className="solution__img-section">
                <img 
                    onLoad={() => this.setState({ img_loaded: true })}
                    style={{opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION}} 
                    src={this.props.img} alt="" 
                    />
                </div>
                <div className="solution__text-flex">
                    <div>
                        <img src={icon1} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionParkVisionTXT.head1}</h2>
                            <p>{solutionParkVisionTXT.text1}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon2} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionParkVisionTXT.head2}</h2>
                            <p>{solutionParkVisionTXT.text2}</p>
                        </span>
                    </div>
                </div>

                <div className="solution__text-flex park__vision__second__sm">
                    <div>
                        <img src={icon3} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionParkVisionTXT.head3}</h2>
                            <p>{solutionParkVisionTXT.text3}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon4} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionParkVisionTXT.head4}</h2>
                            <p>{solutionParkVisionTXT.text4}</p>
                        </span>
                    </div>
                </div>

                <div className="solutions-button">
                    <Link to={"/" + langType + "/contact"}> <button className="button-primary"><p>{solutionParkVisionTXT.btn1}</p></button></Link>
                </div>
            </div>
        );
    }
}

export default ParkVision;