import React, { Component } from 'react'
import { SOLUTIONS_TRANSITION } from '../../../../config/keys'
import '../solutions.css'

import {Link} from 'react-router-dom'
import icon1 from '../../../../img/solutions/wspicon1.svg'
import icon2 from '../../../../img/solutions/wspicon2.svg'

class Wsp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            img_loaded: false
        }
    }
    render() {
        let solutionWspTXT = this.props.lang
        const { img_loaded } = this.state
        let langType = solutionWspTXT.language
        return (
            <div className="wsp">
                <div className="tempo__header">
                    <h1>Wireless Smart-Park</h1>
                </div>
                <div className="solution__img-section">
                    <img
                        onLoad={() => this.setState({ img_loaded: true })}
                        style={{ opacity: img_loaded ? 1 : 0, transition: SOLUTIONS_TRANSITION }}
                        src={this.props.img} alt=""
                    />
                </div>
                <div className="solution__text-flex">
                    <div>
                        <img src={icon1} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionWspTXT.head1}</h2>
                            <p>{solutionWspTXT.text1}</p>
                        </span>
                    </div>
                    <div>
                        <img src={icon2} alt="icon" />
                        <span className="solution__text-flex-texts">
                            <h2>{solutionWspTXT.head2}</h2>
                            <p>{solutionWspTXT.text2}</p>
                        </span>
                    </div>
                </div>
                <div className="solutions-button">
                    <Link to={"/" + langType + "/contact"}> <button className="button-primary"><p>{solutionWspTXT.btn1}</p></button></Link>
                </div>
            </div>
        );
    }
}

export default Wsp;