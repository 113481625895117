import React, { Component } from 'react'
import { Switch, Route, Link, withRouter } from 'react-router-dom'
import ScrollableAnchor from 'react-scrollable-anchor'
import { connect } from 'react-redux'
import * as actions from '../../../actions/actions'
import { configureAnchors } from 'react-scrollable-anchor'
//components
import ParkVision from './parts/ParkVision'
import QuickPark from './parts/QuickPark'
import LedGuide from './parts/LedGuide'
import Metalos from './parts/Metalos'
import Wsp from './parts/Wsp'
//img
import img1 from '../../../img/solutions/PV.png'
import img2 from '../../../img/solutions/QP.png'
import img3 from '../../../img/solutions/LG.png'
import img4 from '../../../img/solutions/MO.png'
import img5 from '../../../img/solutions/wsp.png'
import BackgroundImageForLoad from '../../../img/solutionsBG.png'
import MetaTags, { ReactTitle } from 'react-meta-tags';

class Solutions extends Component {

    componentDidMount() {
        this.props.navbarLoadReset()
    }


    constructor() {
        super()

        this.state = {
            activeNav: window.location.pathname.indexOf('metalos') > -1 
            ? 
                4 
            : 
            window.location.pathname.indexOf('ledguide') > -1 ? 
                3 
            : 
            window.location.pathname.indexOf('wsp') > -1 
            ? 
                5
            : 
            window.location.pathname.indexOf('quickpark') > -1 
            ? 
                2 
            : 
                1,
            class: 'solution__navigation__link--left'
        }
    }
    componentDidMount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        if (window.innerWidth <= 900) {
            this.setState({
                mobileNav: true
            })
        } else {
            this.setState({
                mobileNav: false
            })
        }
    }
    changeActive(value) {
        this.setState({ activeNav: value })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.location) {
            this.setState({
                activeNav: nextProps.location.pathname.indexOf('wsp') > -1 ? 5 : nextProps.location.pathname.indexOf('metalos') > -1 ? 4 : nextProps.location.pathname.indexOf('ledguide') > -1 ? 3 : nextProps.location.pathname.indexOf('quickpark') > -1 ? 2 : 1
            })
        }

    }

    render() {
        let solutionTXT = this.props.lang.solutionTXT
        let langType = solutionTXT.language
        const { background_loaded } = this.state
        configureAnchors({ offset: -90, scrollDuration: 400 })

        return (
            <div className="solutions">
                {/* <ReactTitle title="hello" />
                <MetaTags>
                </MetaTags> */}
                <div className="solutions__grid">
                    <header className="solutions__header">
                        <img 
                            alt="parking sensors"
                            className="home__cover__photo images__transitions" 
                            onLoad={() =>{
                                 this.props.navbarLoad()
                                this.setState({ background_loaded: true })
                            }} 
                            src={BackgroundImageForLoad} 
                            style={{opacity: background_loaded ? 1 : 0, transition: '.5s all'}}  
                        />
                        <h1>{solutionTXT.headerH1}</h1>
                        <h3>{solutionTXT.headerText}</h3>
                        <span className="button__container">
                            <a href='#navsolutions'><button className="button-primary"><p>{solutionTXT.headerBtn1}</p></button></a>
                            <Link to={"/" + langType + "/contact"}><button className="button-secondary"><p>{solutionTXT.headerBtn2}</p></button></Link>
                        </span>
                    </header>
                    <ScrollableAnchor id={'navsolutions'}>
                        <nav className="solution__navigation">
                            <div className="solution__navigation__content">
                                <Link to={"/" + langType + "/solutions/parkvision"} onClick={() => { this.changeActive(1) }}>
                                    <div className={this.state.activeNav === 1 ? "solution__navigation__link--right solution__navigation__link--active" : "solution__navigation__link--right"}>
                                        <p>{solutionTXT.nav1}</p>
                                    </div>
                                </Link>
                                <Link to={"/" + langType + "/solutions/quickpark"} onClick={() => { this.changeActive(2) }}>
                                    <div className={this.state.activeNav === 2 ? "solution__navigation__link--center solution__navigation__link--active" : "solution__navigation__link--center"}>
                                        <p>{solutionTXT.nav2}</p>
                                    </div>
                                </Link>
                                <Link to={"/" + langType + "/solutions/wsp"} onClick={() => { this.changeActive(5) }}>
                                    <div className={this.state.activeNav === 5 ? "solution__navigation__link--center solution__navigation__link--active" : "solution__navigation__link--center"}>
                                        <p>{solutionTXT.nav5}</p>
                                    </div>
                                </Link>
                                <Link to={"/" + langType + "/solutions/ledguide"} onClick={() => { this.changeActive(3) }}>
                                    <div className={this.state.activeNav === 3 ? "solution__navigation__link--center solution__navigation__link--active" : "solution__navigation__link--center"}>
                                        <p>{solutionTXT.nav3}</p>
                                    </div>
                                </Link>
                                <Link to={"/" + langType + "/solutions/metalos"} onClick={() => { this.changeActive(4) }}>
                                    <div className={this.state.activeNav === 4 ? "solution__navigation__link--left solution__navigation__link--active" : "solution__navigation__link--left"}>
                                        <p>{solutionTXT.nav4}</p>
                                    </div>
                                </Link>
                            </div>
                        </nav>
                    </ScrollableAnchor>
                </div>
                <Switch>
                    <Route path={"/" + langType + "/solutions/parkvision"} render={() => <ParkVision img={img1} lang={this.props.lang.solutionParkVisionTXT} />} />
                    <Route path={"/" + langType + "/solutions/quickpark"} render={() => <QuickPark img={img2} lang={this.props.lang.solutionQuickParkTXT} />} />
                    <Route path={"/" + langType + "/solutions/ledguide"} render={() => <LedGuide img={img3} lang={this.props.lang.solutionLedGuideTXT} />} />
                    <Route path={"/" + langType + "/solutions/metalos"} render={() => <Metalos img={img4} lang={this.props.lang.solutionMetalosTXT} />} />
                    <Route path={"/" + langType + "/solutions/wsp"} render={() => <Wsp img={img5} lang={this.props.lang.solutionWspTXT} />} />
                </Switch>

            </div >
        );
    }
}

function stateProps({ load_navbar }) {
    return { load_navbar }
}

export default connect(stateProps, actions)(Solutions)